var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.member && _vm.member.profile
    ? _c(
        "div",
        { staticClass: "member-details" },
        [
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c("a-col", [
                _c("img", {
                  attrs: { src: require("@/assets/custom-icons/email.png") },
                }),
              ]),
              _c("a-col", [
                _c("label", [_vm._v("Email")]),
                _c("p", [_vm._v(_vm._s(_vm.member.work_email))]),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c("a-col", [
                _c("img", {
                  attrs: { src: require("@/assets/custom-icons/calendar.png") },
                }),
              ]),
              _c("a-col", [
                _c("label", [_vm._v("Date of Birth")]),
                _c("p", [_vm._v(_vm._s(_vm.nFormat(_vm.member.profile.dob)))]),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c("a-col", [
                _c("img", {
                  attrs: {
                    src: require("@/assets/custom-icons/mobile-app.png"),
                  },
                }),
              ]),
              _c("a-col", [
                _c("label", [_vm._v("Phone")]),
                _c("p", [_vm._v(_vm._s(_vm.member.profile.phone))]),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c("a-col", [
                _c("img", {
                  attrs: { src: require("@/assets/custom-icons/address.png") },
                }),
              ]),
              _c("a-col", [
                _c("label", [_vm._v("Address")]),
                _c("p", [
                  _vm.member.profile.address_1
                    ? _c("span", [_vm._v(_vm._s(_vm.member.profile.address_1))])
                    : _vm._e(),
                  _vm.member.profile.address_2
                    ? _c("span", [
                        _vm._v(", " + _vm._s(_vm.member.profile.address_2)),
                      ])
                    : _vm._e(),
                  _vm.member.profile.town
                    ? _c("span", [
                        _vm._v(", " + _vm._s(_vm.member.profile.town)),
                      ])
                    : _vm._e(),
                  _vm.member.profile.region
                    ? _c("span", [
                        _vm._v(", " + _vm._s(_vm.member.profile.region)),
                      ])
                    : _vm._e(),
                  _vm.member.profile.postcode
                    ? _c("span", [
                        _vm._v(", " + _vm._s(_vm.member.profile.postcode)),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c("a-col", [
                _c("img", {
                  attrs: { src: require("@/assets/custom-icons/customer.png") },
                }),
              ]),
              _c("a-col", [
                _c("label", [_vm._v("Emergency Contact")]),
                _c("p", [_vm._v(_vm._s(_vm.member.profile.emergency_name))]),
              ]),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c("a-col", [
                _c("img", {
                  attrs: {
                    src: require("@/assets/custom-icons/phone-call.png"),
                  },
                }),
              ]),
              _c("a-col", [
                _c("label", [_vm._v("Emergency Number")]),
                _c("p", [_vm._v(_vm._s(_vm.member.profile.emergency_phone))]),
              ]),
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.makeCoach },
            },
            [_vm._v("\n    Make Coach\n  ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }