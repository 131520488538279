var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    { attrs: { padding: "" } },
    [
      _c("club-member-header", { attrs: { member: _vm.member } }),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xxl: 8, lg: 12, md: 24, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-widget",
                  attrs: { title: "Personal Details" },
                },
                [
                  _c("club-member-details", { attrs: { member: _vm.member } }),
                  _vm.pageLoading
                    ? _c(
                        "p",
                        { staticStyle: { "text-align": "center" } },
                        [_c("a-spin")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }